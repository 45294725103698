import type {Cindedi} from '@cindedi/spec/error';
import {CINDEDI_ERROR_ID} from '../constants/symbols';

export class CindediError extends Error implements Cindedi.Error {
  [CINDEDI_ERROR_ID]: boolean;

  private _message: Cindedi.ErrorMessage;

  get status(): number {
    return this._message.status;
  }

  constructor(titleOrMessage: string | Cindedi.ErrorMessage) {
    const message =
      typeof titleOrMessage === 'string'
        ? {
            status: 500,
            title: titleOrMessage,
            type: 'about:blank',
          }
        : {
            type: 'about:blank',
            ...titleOrMessage,
          };

    super(message.title);
    this._message = message;
    this[CINDEDI_ERROR_ID] = true;
  }

  set(partial: Partial<Cindedi.ErrorMessage>): this {
    this._message = {...this._message, ...partial};
    return this;
  }

  serialize(options: Cindedi.ErrorSerializerOptions = {}): Cindedi.ErrorMessage[] {
    return options.includeStack ? [{...this._message, stack: this.stack}] : [this._message];
  }
}
